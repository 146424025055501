<template>
  <div class="admin-wizard">
    <AdminHeader />
    <AdminBreadcrumb @on-saveContinue="onFormSubmit(true)" />
    <main class="content">
      <b-container>
        <b-row>
          <b-col md="3">
            <AdminSideNav />
          </b-col>
          <b-col md="9">
            <ValidationObserver ref="observer" v-slot="{ invalid }">
              <b-card>
                <b-card-body class="p-0">
                  <b-form @submit.prevent="onFormSubmit">
                    <b-card-title
                      >Add your event.
                      <span class="font-weight-normal">(Optional)</span>
                    </b-card-title>
                    <div class="guide-tip">
                      <b-row align-v="center">
                        <b-col cols="3" lg="1" class="icon">
                          <font-awesome-icon :icon="['far', 'calendar-alt']" />
                        </b-col>
                        <b-col
                          cols="12"
                          lg="8"
                          order="3"
                          order-lg="2"
                          class="text"
                        >
                          If you do not have any events, you can always come
                          back and add one later. The
                          <span class="font-weight-bold">Button Link</span>
                          field below can be used to drive people to your event
                          page on Facebook, Eventbrite, or any other service.
                        </b-col>
                        <b-col
                          cols="9"
                          lg="3"
                          order="2"
                          order-lg="3"
                          class="button"
                        >
                          <b-button
                            variant="outline-secondary"
                            size="sm"
                            v-b-modal.modal-theme-guide
                          >
                            View Example
                            <font-awesome-icon
                              :icon="['far', 'window-maximize']"
                            />
                          </b-button>
                        </b-col>
                      </b-row>
                    </div>
                    <b-alert :show="!!errorMessage" variant="danger">{{
                      errorMessage
                    }}</b-alert>
                    <b-form-group>
                      <ValidationProvider
                        name="Event Title"
                        rules="required"
                        v-slot="validationContext"
                      >
                        <label for="input-event-title">
                          Event Title <span class="asterisk">*</span>
                        </label>
                        <b-form-input
                          id="input-event-title"
                          v-model="form.title"
                          :state="getValidationState(validationContext)"
                          placeholder="Enter your event title"
                        ></b-form-input>
                        <b-form-invalid-feedback>{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-row>
                      <b-form-group class="col-md-6">
                        <ValidationProvider
                          name="Event Date"
                          rules="required"
                          v-slot="validationContext"
                        >
                          <label for="input-event-date">
                            Event Date <span class="asterisk">*</span>
                          </label>
                          <b-form-datepicker
                            placeholder="Select date"
                            id="event-date"
                            v-model="form.date"
                            :state="getValidationState(validationContext)"
                          ></b-form-datepicker>
                          <b-form-invalid-feedback>{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </ValidationProvider>
                      </b-form-group>
                      <b-form-group class="col-md-6">
                        <ValidationProvider
                          name="Event Time"
                          rules="required"
                          v-slot="validationContext"
                        >
                          <label for="input-event-time">
                            Event Time <span class="asterisk">*</span>
                          </label>
                          <b-form-timepicker
                            v-model="form.time"
                            :state="getValidationState(validationContext)"
                            placeholder="Enter your event time"
                          ></b-form-timepicker>
                          <b-form-invalid-feedback>{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </ValidationProvider>
                      </b-form-group>
                    </b-form-row>
                    <b-form-row>
                      <b-form-group class="col-md-12">
                        <ValidationProvider
                          name="Event Description"
                          rules="required"
                          v-slot="validationContext"
                        >
                          <label for="input-event-description">
                            Event Description <span class="asterisk">*</span>
                          </label>
                          <b-form-textarea
                            id="event-description"
                            v-model="form.description"
                            :state="getValidationState(validationContext)"
                            placeholder="Enter your event description"
                            rows="4"
                            max-rows="6"
                          ></b-form-textarea>
                          <b-form-invalid-feedback>{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </ValidationProvider>
                      </b-form-group>
                    </b-form-row>
                    <b-form-row>
                      <b-form-group
                        label="Button Text"
                        label-class="font-weight-bold"
                        label-for="input-ctatext"
                        class="col-md-6"
                      >
                        <ValidationProvider
                          name="Button Text"
                          v-slot="validationContext"
                          :rules="form.ctaLink ? 'required' : ''"
                        >
                          <b-form-input
                            id="input-ctatext"
                            v-model="form.ctaText"
                            :state="getValidationState(validationContext)"                            
                            placeholder="Ex: RSVP"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </ValidationProvider>
                      </b-form-group>
                      <b-form-group
                        label="Button Link"
                        label-class="font-weight-bold"
                        label-for="input-ctalink"
                        class="col-md-6"
                      >
                        <ValidationProvider
                          name="Button Link"
                          :rules="form.ctaText ? 'required|url' : 'url'"
                          v-slot="validationContext"
                        >
                          <b-form-input
                            id="input-ctalink"
                            v-model="form.ctaLink"
                           :state="getValidationState(validationContext)"                           
                            placeholder="Ex: https://www.eventbrite.com/event"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </ValidationProvider>
                      </b-form-group>
                    </b-form-row>
                    <b-form-row v-if="usesPhoto">
                      <b-form-group
                        label="Cover Photo"
                        label-class="font-weight-bold"
                        label-for="input-photo"
                        class="col-md-6"
                      >
                        <ValidationProvider
                          name="Cover Photo"
                          v-slot="validationContext"
                        >
                          <b-form-file
                            v-model="form.coverPhoto"
                            :state="getValidationState(validationContext)"
                            accept="image/*"
                            placeholder="Select a photo"
                          ></b-form-file>
                          <b-form-invalid-feedback>{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </ValidationProvider>
                      </b-form-group>
                    </b-form-row>
                    <b-button
                      v-show="false"
                      ref="submit"
                      type="submit"
                      variant="primary"
                      >Submit</b-button
                    >
                  </b-form>
                </b-card-body>
              </b-card>
              <AdminFooterButton
                @on-save="onSave"
                @on-back="onBack"
                @on-preview="onPreview"
                @on-skip="onSkip"
                :saving="saving"
                :invalid="invalid"
              />
            </ValidationObserver>
          </b-col>
        </b-row>
      </b-container>
    </main>
    <ModalThemeGuide
      theme-id="events"
      :template-id="candidateModel.profileTheme"
    />
  </div>
</template>

<script>
import DirtyFlagMixin from "@/mixins/dirtyflag.mixin";
import CandidateMixin from "@/mixins/candidate.mixin";
import { FormatDate } from "@/services/helpers";

export default {
  name: "AddEvent",
  mixins: [DirtyFlagMixin, CandidateMixin],
  data() {
    return {
      form: {
        title: null,
        date: null,
        time: null,
        description: null,
        ctaText: null,
        ctaLink: null,
        status: null,
        coverPhoto: null,
        visible: true
      },
      listOfStatus: [
        { value: null, text: "Please select status" },
        { value: "Upcoming", text: "Upcoming" },
        { value: "Past", text: "Past" }
      ],
      usesPhoto: true
    };
  },
  mounted() {
    this.templateUsesPhoto();
  },

  methods: {
    onPreview() {},
    onBack() {
      this.$router.push("social-links");
    },
    onSkip() {
      if (this.candidateModel.events.length > 0)
        this.$router.push("manage-events");
      else this.$router.push("add-endorsement");
    },
    onSave() {
      this.$refs.submit.click();
    },
    templateUsesPhoto() {
      var candidate = this.$store.state.candidates.candidate;
      var template = candidate.profileTheme;
      if (template == 2 || template == 4) {
        this.usesPhoto = false;
      }
    },
    validateButton(validationState, pairedInput){     
      if(validationState == false){
        return false;
      }
      if(pairedInput && !this.value){       
        return false;
      }
    },
    onFormSubmit(fromBreadcrumb) {
      this.isSubmitting = true;
      this.saving = true;

      this.form.userId = this.$route.params.id;
      let eventDate = new Date(this.form.date);
      if (eventDate > Date.now()) this.form.status = "Upcoming";
      else this.form.status = "Past";

      this.$refs.observer.validate().then(valid => {
        if (valid) {
          this.candidateService
            .SaveEventAsync(this.form)
            .then(res => {
              this.$store.commit("candidates/SET_CANDIDATE", res.data);
              setTimeout(() => {
                this.saving = false;
                if (fromBreadcrumb != true) {
                  this.$router.push("manage-events");
                }
              }, 500);
            })
            .catch(e => {
              this.saving = false;
              this.errorMessage = e;
            });
        } else {
          this.saving = false;
          this.errorMessage = "Please fill up required fields!";
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/admin/_base.scss";
</style>
